a {
  color: #444444;
  font-weight: bold;
  text-decoration: none;
}

a:hover {
  color: #3b7ba8;
}

a:active {
  color: #3b7ba8;
}

body {
  background: #f1f1f1;
  color: #7a7c84;
  font-family: "Merriweather Sans",sans-serif;
  font-size: 20px;
  line-height: 1.5;
  padding: 20px;
}

figure {
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

i {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  float: left;
  margin-right: 5px;
  margin-top: 7px;
  padding: 3px;
}

i.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

i.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

i.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

i.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

img {
  height: auto;
  max-width: 100%;
}

.blog-content {
  float: left;
  width: 65%;
}

.blog-navigation {
  float: left;
  width: 35%;
}

.blog-navigation > div:first-child {
  padding-left: 45px;
}

.fake-img {
  background-color: #a0a0a0;
  padding: 20px;
}

.card {
  background-color: #ffffff;
  margin-top: 45px;
  padding: 50px;
}

.footer {
  background: #d0d0d0;
  margin-top: 20px;
  padding: 20px;
  text-align: center;
}

.header {
  background: #ffffff;
  font-size: 40px;
  padding: 30px;
  text-align: center;
  text-decoration: none;
}

.indented {
  display: block;
  padding-left: 10px;
}

.my-photo {
  float: left;
  margin-right: 15px;
  width: 150px;
}

.row:after {
  clear: both;
  content: "";
  display: table;
}

.show-more {
  color: #444;
  cursor: pointer;
  font-weight: bold;
  margin: 25px;
}

@media screen and (max-width: 800px) {
  body {
    font-size: 16px;
  }
  
  .blog-content, .blog-navigation {
    padding: 0;
    width: 100%;
  }

  .blog-navigation > div:first-child {
    padding: 0;
  }

  .card {
    padding: 20px;
  }
}